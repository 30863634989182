/* ---------------------------- React Router Dom ---------------------------- */
import { useLocation, useNavigate } from "react-router-dom";

/* ---------------------------------- Icons --------------------------------- */
import next from "../assets/img/Icons/next-btn.svg";
import prev from "../assets/img/Icons/prev-btn.svg";

const Button = ({ text, link, image,className }) => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <button
      className={`btn ${className}`}
      style={{ flexDirection: image === "next" ? "row-reverse" : "row" }}
      onClick={() => {
        if (location.pathname === "check-up") {
          return true;
        } else {
          navigate(link);
        }
      }}
    >
      {image && (
        <img src={image === "next" ? next : prev} alt="prev" className="prev" />
      )}
      {text}
    </button>
  );
};

export default Button;

