import { useContext, useEffect, useState } from "react";
import close from "../../assets/img/Icons/close-circle.svg";
import tick from "../../assets/img/Icons/tick-bold.svg";
import { AppContext } from "../../utils/AppContext";
const SideBar = () => {
  const { symptomBar, setSymptomBar,sideBarCaption,setSideBarCaption, keywords, setKeywords } =
    useContext(AppContext);

  // test
  const [data, setData] = useState([
    { id: 1, symptom: "Head" },
    { id: 4, symptom: "leg" },
    { id: 3, symptom: "Upper abdomen" },
    { id: 6, symptom: "chest" },
    { id: 2, symptom: "Eyes" },
  ]);
  const[staticData,setStaticData]=useState([
    { id: 1, symptom: "Head" },
    { id: 4, symptom: "Eyes" },
    { id: 3, symptom: "Ears" },
    { id: 6, symptom: "Nose" },
    { id: 2, symptom: "Oral cavity" },
  ]);
  useEffect(()=>{
    if(sideBarCaption==="Select the exact location on the body"){
      setData(staticData)
    }
  },[sideBarCaption, staticData])

const handlelick =(symptom)=>{
  if(sideBarCaption!=="Select the exact location on the body"){

    if (keywords.includes(symptom)) {
      setKeywords(keywords.filter((key) => key !== symptom));
    } else {
  
      setKeywords([...keywords, String(symptom)]);
    }
  }else{
    setSideBarCaption(symptom)
  }
}

  return (
    <div className={symptomBar ? " side-bar isOpenMenu" : "side-bar"}>
      <div className="top">
        <h3 className="body-part">{sideBarCaption}</h3>
        <div
          className="close-icon"
          onClick={() => {
            setSymptomBar(false);
          }}
        >
          <img src={close} alt="close-icon" />
        </div>
      </div>
      <div className="bottom">
        <ul className="symptom-list">
          {data.map((item) => (
            <li
              className="symptom-item"
              key={item.id}
              onClick={() => handlelick(item.symptom)}
            >
              <span>{item.symptom}</span>
              {keywords.includes(item.symptom) && sideBarCaption!=="Select the exact location on the body" && (
                <div className="tick">
                  <img src={tick} alt="tick" />
                </div>
              )}
              {sideBarCaption==="Select the exact location on the body" && (
                <div className="tick">
                  <img src={tick} alt="tick" />
                </div>
              )}

            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SideBar;
