import consulting from "../assets/img/consulting.svg";
import anket from "../assets/img/anket.svg";
import phone from "../assets/img/phone.svg";
const homeTest2 = [
  {
    id: 1,
    img: consulting,
    title: "Cancer Care",
    text: "The gradual accumulation of information about",
  },
  {
    id: 2,
    img: anket,
    title: "Online Appoinment",
    text: "The gradual accumulation of information about",
  },
  {
    id: 3,
    img: phone,
    title: "Book now",
    text: "The gradual accumulation of information about",
  },
];
export default homeTest2;
