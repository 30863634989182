import { Link, useLocation } from "react-router-dom";
import Logo from "../assets/img/Icons/logo.svg";
import LogoBase from "../assets/img/Icons/logo-base.svg";
import logoWhite from "../assets/img/Icons/logo-white.svg";
import Button from "./Button";
import { useEffect, useState } from "react";

const Header = () => {
  const location = useLocation();
  const [isScrolled, setIsScrolled] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const positionStyle = {
    position: "fixed",
    top: 0,
    left: "0",
  };

  useEffect(() => {
    if (location.pathname === "/") {
      const handleScroll = () => {
        setIsScrolled(window.scrollY >= 200);
      };
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [location]);

  // Detect device width for render box component
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <header
      className={
        location.pathname === "/"
          ? "header "
          : location.pathname === "/check-up"
          ? "header checkup-header"
          : location.pathname !== "/" && location.pathname !== "check-up"
          ? "header nonVisible"
          : null
      }
      style={
        location.pathname === "/" && isScrolled
          ? positionStyle
          : { position: "static" }
      }
    >
      <div className="container">
        <div className="row">
          <Link to="/" className="logo">
            <img
              src={
                location.pathname === "/"
                  ? windowWidth < 578
                    ? LogoBase
                    : Logo
                  : logoWhite
              }
              alt="logo"
            />
          </Link>
          <Button
            text={"Get Start"}
            link={"/check-up"}
            className={location.pathname === "/" && isScrolled && "active"}
          />
        </div>
      </div>
    </header>
  );
};

export default Header;
