/* ---------------------------------- Image --------------------------------- */
import { useContext, useEffect } from "react";
import Banner from "../../assets/img/general-info-banner.png";
import { AppContext } from "../../utils/AppContext";

const GeneralInfo = () => {
/* --------------------------------- Context -------------------------------- */
const {nextButtonRef,setCheckupPage}=useContext(AppContext)

  /* ---------------------------- Next Button Usage --------------------------- */
  useEffect(() => {
    const nextButton = nextButtonRef && nextButtonRef.current;

    const handleClick = () => {
      setCheckupPage("personal-select")
    };


    if (nextButton) {
      nextButton.addEventListener("click", handleClick);

      return () => {
        // Cleanup event listener on component unmount
        nextButton.removeEventListener("click", handleClick);
      };
    }
  }, [nextButtonRef, setCheckupPage]);

  return (
    <div className="general-info">
      <div className="row">
        <div className="left-side">
          <h3 className="title">Check your symptoms</h3>
          <h6 className="subtitle">
            After a short interview, you will be able to get a brief information
            about your symptoms
          </h6>
          <h6 className="title">Where do we come to the conclusion?</h6>
          <ul className="list">
            <li className="item">Our certified doctors</li>
            <li className="item">Confirmed in the health organization</li>
          </ul>
        </div>
        <div className="right-side">
          <img src={Banner} alt="banner" />
        </div>
      </div>
    </div>
  );
};

export default GeneralInfo;
