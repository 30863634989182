
const ResultSymptom = () => {
  return (
    <div className="result-symptom">
      <div className="result-container">
        <h6 className="caption">The possibility of other diseases</h6>
        <div className="illness">
          <div className="degree">
            <div className="status">
              <span style={{ width: "80%" }}></span>
            </div>
            <p className="text">Moderate evidence</p>
          </div>
          <h6 className="ilness-name">Migraine</h6>
        </div>
        <div className="illness">
          <div className="degree">
            <div className="status">
              <span style={{ width: "80%" }}></span>
            </div>
            <p className="text">Moderate evidence</p>
          </div>
          <h6 className="ilness-name">Migraine</h6>
        </div>
        <p className="alert">
          List of possible conditions may not be complete, is provided solely
          for informational purposes, is not a qualified medical opinion and can
          not replace the medical diagnosis.
        </p>
      </div>
    </div>
  );
};

export default ResultSymptom;
