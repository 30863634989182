/* ---------------------------------- React --------------------------------- */
import { useContext, useEffect, useState } from "react";

/* --------------------------------- Context -------------------------------- */
import { AppContext } from "../../utils/AppContext";

/* ------------------------------- Components ------------------------------- */
import CheckupCaption from "../../component/checkup/CheckupCaption";
import CheckUpPagination from "../../component/checkup/CheckUpPagination";
import GeneralInfo from "../../component/checkup/GeneralInfo";
import PersonalGenderSelect from "../../component/checkup/PersonalGenderSelect";
import SpecificInfo from "../../component/checkup/SpecificInfo";
import StaticSymptom from "../../component/checkup/StaticSymptom";
import SelectSymptom from "../../component/checkup/SelectSymptom";
import SelectRegion from "../../component/checkup/SelectRegion";
import DynamicSymptom from "../../component/checkup/DynamicSymptom";
import YesNoQuestion from "../../component/checkup/YesNoQuestion";
import PainLevel from "../../component/checkup/PainLevel";
import questionsDb from "../../db/questionsDb";

const CheckupArea = () => {
  const { checkupPage } = useContext(AppContext);
  const [question, setQuestion] = useState("");

  useEffect(() => {
    const foundQuestion = questionsDb.find((item) => {
      if (checkupPage === "general-info" || checkupPage === "specific-info") {
        return false;
      } else {
        return item.page === checkupPage;
      }
    });

    if (foundQuestion) {
      setQuestion(foundQuestion.question);
    }
  }, [checkupPage]);
  return (
    <div className="checkup-area">
      <div
        className={
          checkupPage === "general-info" || checkupPage === "specific-info"
            ? "checkup-container deActiveQuestion"
            : "checkup-container"
        }
      >
        {checkupPage !== "general-info" && checkupPage !== "specific-info" && (
          <CheckupCaption title={question} />
        )}
        {checkupPage === "general-info" ? (
          <GeneralInfo />
        ) : checkupPage === "personal-select" ? (
          <PersonalGenderSelect />
        ) : checkupPage === "gender-select" ? (
          <PersonalGenderSelect />
        ) : checkupPage === "specific-info" ? (
          <SpecificInfo />
        ) : checkupPage === "static-symptom" ? (
          <StaticSymptom />
        ) : checkupPage === "select-symptom" ? (
          <SelectSymptom />
        ) : checkupPage === "select-region" ? (
          <SelectRegion />
        ) : //  loop
        checkupPage === "dynamic-symptom" ? (
          <DynamicSymptom />
        ) : checkupPage === "yes-no-question" ? (
          <YesNoQuestion />
        ) : checkupPage === "pain-level" ? (
          <PainLevel />
        ) : null}
        <CheckUpPagination />
      </div>
    </div>
  );
};

export default CheckupArea;
