/* ---------------------------------- React --------------------------------- */
import { useContext, useEffect, useState } from "react";

/* -------------------------------- Component ------------------------------- */
import SearchSymptom from "./SearchSymptom";
import SearchKeywordsArea from "./SearchKeywordsArea";
import BodyDesktop from "./BodyDesktop";
import BodyMobile from "./BodyMobile";
import SideBar from "./SideBar";
import { AppContext } from "../../utils/AppContext";

const SelectSymptom = () => {
  /* --------------------------------- Context -------------------------------- */
  const { symptomBar, setSymptomBar,prevButtonRef,nextButtonRef,setCheckupPage } = useContext(AppContext);

  // Detect device width for render box component
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  /* ---------------------------- Prev Button Usage --------------------------- */
  useEffect(() => {
    const prevButton = prevButtonRef && prevButtonRef.current;

    const handleClick = () => {
      setCheckupPage("static-symptom");
    };

    if (prevButton) {
      prevButton.addEventListener("click", handleClick);

      return () => {
        // Cleanup event listener on component unmount
        prevButton.removeEventListener("click", handleClick);
      };
    }
  }, [prevButtonRef, setCheckupPage]);

  

  /* ---------------------------- Next Button Usage --------------------------- */
  useEffect(() => {
    const nextButton = nextButtonRef && nextButtonRef.current;

    const handleClick = () => {
      setCheckupPage("select-region");
    };



    if (nextButton) {
      nextButton.addEventListener("click", handleClick);

      return () => {
        // Cleanup event listener on component unmount
        nextButton.removeEventListener("click", handleClick);
      };
    }
  }, [nextButtonRef, setCheckupPage]);

  return (
    <div className="select-symptom">
      <div className="left-side">
        <SearchSymptom />
        <SearchKeywordsArea />
      </div>
      <div className="right-side">
        {windowWidth > 1000 ? <BodyDesktop /> : <BodyMobile />}
      </div>
      <SideBar />
      <div className={symptomBar ? "overlay active" : "overlay"} onClick={()=>setSymptomBar(false)}></div>
    </div>
  );
};

export default SelectSymptom;
