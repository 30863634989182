import React from "react";

const ResultGeneralInfo = () => {
  return (
    <div className="result-general-info">
      <div className="result-container">
        <h2 className="title">Consult a doctor</h2>
        <p className="text">
          Your symptoms may require medical evaluation. Schedule an appointment
          with your doctor. If your symptoms get worse, see a doctor
          immediately.
        </p>
        <div className="recommended-area">
          <div className="card">
            <h6 className="title">Recommended doctor</h6>
            <h4 className="medical-field">General Practitioner</h4>
          </div>
          <span className="stroke"></span>
          <div className="card">
            <h6 className="title">Recommended appointment type</h6>
            <h4 className="contact-type">Telephone consultation</h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResultGeneralInfo;
