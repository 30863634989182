import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string } from "yup";
import { useState } from "react";
import SeacrhLoadingItem from "./SeacrhLoadingItem";

const SearchSymptom = () => {
  const [searchContentVisible, setSearchContentVisible] = useState(false);

  const searchSchema = object({
    search: string().trim(),
  });

  const {
    register,
    // handleSubmit,
    // setValue,
  } = useForm({ resolver: yupResolver(searchSchema) });

  const handleCheck = (value) => {
    if (String(value).length > 2) {
      setSearchContentVisible(true);
      console.log(value);
    } else {
      setSearchContentVisible(false);
    }
  };
  console.log();
  return (
    <div className="seacrh-symptom">
      <h6 className="title">
        Add as many symptoms as you can for the most accurate results.
      </h6>
      <form className="seacrh-form" onSubmit={(e)=>e.preventDefault()}>
        <input
          type="text"
          className="seacrh-inp"
          name="search"
          placeholder="Search, e.g., headache"
          autoComplete="off"
          {...register("search", {
            onChange: (e) => {
              handleCheck(e.target.value.trim());
            },
          })}
        />
        <ul
          className={
            searchContentVisible ? "search-content active" : "search-content"
          }
        >
          <li
            className="seacrh-item"
            onClick={() => {
              setSearchContentVisible(false);
            }}
          >
            asd
          </li>
          <li
            className="seacrh-item"
            onClick={() => {
              setSearchContentVisible(false);
            }}
          >
            asd
          </li>
          <li
            className="seacrh-item"
            onClick={() => {
              setSearchContentVisible(false);
            }}
          >
            asd
          </li>
          <li
            className="seacrh-item"
            onClick={() => {
              setSearchContentVisible(false);
            }}
          >
            asd
          </li>
          {/* { 
  Array.from({ length: 5 }).map((_, i) => (
    <SeacrhLoadingItem key={i} />
  ))
} */}
        </ul>
      </form>
      <div className={searchContentVisible ?  "search-overlay active" :"search-overlay"} onClick={()=>{setSearchContentVisible(false)}}></div>
    </div>
  );
};

export default SearchSymptom;
