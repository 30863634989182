import React, { useContext } from "react";
import ProgressBar from "../component/checkup/ProgressBar";
import CheckupArea from "./section/CheckupArea";
import { AppContext } from "../utils/AppContext";
import Result from "./section/ResultPage";

const CheckUpPage = () => {
  const { complete } = useContext(AppContext);
  return (
    <main>
      <section className="check-up">
        <div className="container">
          <ProgressBar />
          {!complete ? <CheckupArea /> : <Result />}
        </div>
      </section>
    </main>
  );
};

export default CheckUpPage;
