import React from "react";
import Hero from "./section/Hero";
import MedCheckupInfo from "./section/MedCheckupInfo";
import Reference from "./section/Reference";
import Impressions from "./section/Impressions";
import WebsiteDescription from "./section/WebsiteDescription";

const Home = () => {
  return (
    <main>
      <Hero />
      <MedCheckupInfo />
      <Reference />
      <Impressions />
      <WebsiteDescription/>
    </main>
  );
};

export default Home;
