import React from "react";
import { Route, Routes } from "react-router-dom";
import Header from "./component/Header";
import Footer from "./component/Footer";
import Home from "./page/Home";
import NotFoundPage from "./page/NotFoundPage";
import CheckUpPage from "./page/CheckUpPage";
import { MainContext } from "./utils/AppContext";

const App = () => {
  return (
    <>
      <Header />
      <MainContext>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="check-up" element={<CheckUpPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </MainContext>
      <Footer />
    </>
  );
};

export default App;
