/* ---------------------------------- React --------------------------------- */
import { useContext, useEffect, useRef } from "react";

/* --------------------------------- Context -------------------------------- */
import { AppContext } from "../../utils/AppContext";

/* ---------------------------------- Video --------------------------------- */
import video from "../../assets/videos/video.mp4";

/* ---------------------------------- Icon ---------------------------------- */
import close from "../../assets/img/Icons/close-circle-dark.svg";

const WebsiteVideo = () => {

  /* ------------------------------ Video pick ------------------------------ */
  const videoRef = useRef(null);

  /* --------------------------------- Context -------------------------------- */
  const { videoVisible, setVideoVisible } = useContext(AppContext);

  /* ------------------------------- Reset Video ------------------------------ */
  useEffect(() => {
    if (videoVisible === false && videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
    }
  }, [videoVisible]);

  return (
    <div className="modal">
      <div className="container">
        <div className="row">
          <div className="video">
            <video
              ref={videoRef}
              src={video}
              controls
              muted
            ></video>
          </div>
          <div className="close" onClick={() => setVideoVisible(false)}>
            <img src={close} alt="close-icon" /> <span>Close</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebsiteVideo;
