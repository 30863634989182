/* ---------------------------------- React --------------------------------- */
import { useCallback, useContext, useEffect, useState } from "react";

/* ----------------------------- React Hook Form ---------------------------- */
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string } from "yup";

/* --------------------------------- Context -------------------------------- */
import { AppContext } from "../../utils/AppContext";

/* -------------------------------- DataBase -------------------------------- */
// test
import staticSymptom from "../../db/staticSymptom";

const DynamicSymptom = () => {
  /* ------------------------------- Local State ------------------------------ */
  const [questionType, setQuestionType] = useState("checkbox");
  const [checkboxError, setCheckboxError] = useState(false);
  
  /* --------------------------------- Context -------------------------------- */
  const { nextButtonRef, prevButtonRef, setCheckupPage } =
  useContext(AppContext);
  
  
  /* ------------------------------- Yup Schema ------------------------------- */
  // Radio Schema
  const radioSchema = object({ radio: string().trim() });

  // Checkbox Schema
  const checkboxSchema = object(
    staticSymptom.reduce((acc, item) => {
      acc[item.key] = string().required().trim();
      return acc;
    }, {})
  );

  /* ----------------------------- React Hook Form ---------------------------- */
  const {
    register,
    handleSubmit,
    formState: { errors },
    // setValue,
  } = useForm({
    resolver: yupResolver(
      questionType === "checkbox" ? checkboxSchema : radioSchema
    ),
  });

  /* ------------------------------- Submit Data ------------------------------ */
  const onSubmit = useCallback(
    (data) => {
      if (questionType === "checkbox") {
        Object.keys(data).forEach((key) => {
          if (data[key] !== "false") {
            setCheckboxError(false);
            // console.log(data);
            setCheckupPage("yes-no-question");
          } else {
            setCheckboxError(true);
          }
        });
      } else if (questionType === "radio") {
        console.log(data);
        setCheckupPage("yes-no-question");
      } else {
        return null;
      }
      /* --------------------- radio olduqda data baxilmasina --------------------- */
    },
    [questionType, setCheckupPage]
  );


  /* ---------------------------- Prev Button Usage --------------------------- */
  useEffect(() => {
    const prevButton = prevButtonRef && prevButtonRef.current;
    const handleClick = () => {
      setCheckupPage("select-region");
    };
    if (prevButton) {
      prevButton.addEventListener("click", handleClick);
      return () => {
        prevButton.removeEventListener("click", handleClick);
      };
    }
  }, [prevButtonRef, handleSubmit, setCheckupPage]);

  /* ---------------------------- Next Button Usage --------------------------- */
  useEffect(() => {
    const nextButton = nextButtonRef && nextButtonRef.current;

    const handleClick = async () => {
      const result = await handleSubmit(onSubmit)();
      if (result === undefined || errors.radio || checkboxError) {
        return;
      }
    };
    if (nextButton) {
      nextButton.addEventListener("click", handleClick);
      return () => {
        nextButton.removeEventListener("click", handleClick);
      };
    }
  }, [
    nextButtonRef,
    handleSubmit,
    setCheckupPage,
    errors.radio,
    checkboxError,
    questionType,
    onSubmit,
  ]);

  return (
    <div className="dynamic-symptom">
      <p className="alert">Select one answer in each row</p>
      <form className="dynamic-form" onSubmit={handleSubmit(onSubmit)}>
        {staticSymptom.map((item) => (
          <div className="form-group" key={item.key}>
            <input
              type={questionType === "checkbox" ? "checkbox" : "radio"}
              name={questionType === "checkbox" ? item.key : "radio"}
              id={item.key}
              className={
                questionType === "checkbox"
                  ? checkboxError
                    ? "checkbox error"
                    : "checkbox"
                  : errors.radio
                  ? "radio-inp error"
                  : "radio-inp"
              }
              {...register(
                questionType === "checkbox" ? `${item.key}` : "radio"
              )}
              value={item.key}
              onClick={() => {
                if (questionType === "checkbox" && checkboxError) {
                  setCheckboxError(false);
                }
              }}
            />
            <label htmlFor={item.key}>{item.symptom}</label>
          </div>
        ))}
      </form>
      {questionType === "radio"
        ? errors.radio && (
            <div className="error">
              <p className="text">Choose one</p>
            </div>
          )
        : checkboxError && (
            <div className="error">
              <p className="text">At least one must be selected</p>
            </div>
          )}
    </div>
  );
};

export default DynamicSymptom;
