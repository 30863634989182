import React from 'react'

const HomeCard = ({data}) => {
  return (
    <div className="home-card">
    <div className="top">
      <img src={data.img} alt="icon" />
    </div>
    <div className="bottom">
      <h6 className="title">{data.title}</h6>
      <span className="line"></span>
      <p className="text">
      {data.text}
      </p>
    </div>
  </div>
  )
}

export default HomeCard