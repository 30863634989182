/* -------------------------------- Component ------------------------------- */
import HomeCard from "../../component/HomeCard";
import HomeCaption from "./HomeCaption";

/* --------------------------------- Test Db -------------------------------- */
import homeTest1 from "../../db/homeTestDb";

const MedCheckupInfo = () => {
  return (
    <section className="medCheckup-info">
      <div className="container">
        <div className="row">
          <HomeCaption
            title={"How is SymptomChecker"}
            detail={
              "Problems trying to resolve the conflict between the two major realms of Classical physics: Newtonian mechanics "
            }
            longText={true}
          />
          <div className="info">
            {homeTest1.map((item) => (
              <HomeCard data={item} key={item.id} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default MedCheckupInfo;
