import { useContext } from "react";
import close from "../../assets/img/Icons/close-circle.svg";
import { AppContext } from "../../utils/AppContext";

const SearchKeywordsArea = () => {
  const { keywords, removeKeywords } = useContext(AppContext);
  return (
    <div className="keywords-area">
      {keywords.length === 0 && (
        <p className="alert">Please try to add more than one symptom</p>
      )}
      {keywords.map((key) => (
        <div className="item" key={key}>
          <span className="text">{key} </span>
          <div className="close" onClick={() => removeKeywords(key)}>
            <img src={close} alt="close-icon" />
          </div>
        </div>
      ))}
    </div>
  );
};

export default SearchKeywordsArea;
