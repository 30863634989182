/* ----------------------------- React Hook Form ---------------------------- */
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string } from "yup";

/* -------------------------------- DataBase -------------------------------- */
// test
import staticSymptom from "../../db/staticSymptom";
import { useCallback, useContext, useEffect } from "react";
import { AppContext } from "../../utils/AppContext";

const StaticSymptom = () => {
  /* --------------------------------- Context -------------------------------- */
  const { nextButtonRef, setCheckupPage, prevButtonRef,staticSymptomData,setStaticSymptomData } =
    useContext(AppContext);

  /* ------------------------------- Yup Schema ------------------------------- */
  const symptomSchema = object(
    staticSymptom.reduce((acc, item) => {
      acc[item.key] = string().required().trim();
      return acc;
    }, {})
  );

  /* ----------------------------- React Hook Form ---------------------------- */
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({ resolver: yupResolver(symptomSchema) });

  /* -------------------------------- Set Value ------------------------------- */
  useEffect(()=>{
    if(staticSymptomData){
      Object.keys(staticSymptomData).forEach((key) => {
        setValue(key, staticSymptomData[key]);
      });
    }
  },[setValue, staticSymptomData])


  /* ------------------------------- Submit data ------------------------------ */
  const onSubmit =useCallback( (data) => {
    console.log(data);
    setStaticSymptomData(data)
    setCheckupPage("select-symptom");
  },[setCheckupPage, setStaticSymptomData]);

  /* ---------------------------- Prev Button Usage --------------------------- */
  useEffect(() => {
    const prevButton = prevButtonRef && prevButtonRef.current;

    const handleClick = () => {
      setCheckupPage("specific-info");
    };

    if (prevButton) {
      prevButton.addEventListener("click", handleClick);

      return () => {
        prevButton.removeEventListener("click", handleClick);
      };
    }
  }, [prevButtonRef, handleSubmit, setCheckupPage]);

  

  /* ---------------------------- Next Button Usage --------------------------- */
  useEffect(() => {
    const nextButton = nextButtonRef && nextButtonRef.current;

    const handleClick = () => {
      handleSubmit(onSubmit)();
    };

 
    if (nextButton) {
      nextButton.addEventListener("click", handleClick);

      return () => {
        nextButton.removeEventListener("click", handleClick);
      };
    }
  }, [nextButtonRef, handleSubmit, onSubmit]);

  return (
    <div className="static-dynamic-symptom">
      <p className="alert">Select one answer in each row</p>
      <form className="symptom-form">
        {staticSymptom.map((item) => (
          <div className="form-group" key={item.key}>
            <label className="symptom" htmlFor={item.key}>
              {item.symptom}
            </label>
            <div className="inp-area">
              <div className="inp-group">
                <input
                  type="radio"
                  name={item.key}
                  className={errors[item.key] ? "radio-inp error" : "radio-inp "}
                  value="yes"
                  {...register(`${item.key}`)}
                />
                <span className={errors[item.key] ? "text  textError" : "text"}>
                  Yes
                </span>
              </div>
              <div className="inp-group">
                <input
                  type="radio"
                  name={item.key}
                  className={errors[item.key] ? "radio-inp error" : "radio-inp "}
                  value="no"
                  {...register(`${item.key}`)}
                />
                <span className={errors[item.key] ? "text  textError" : "text"}>
                  No
                </span>
              </div>
              <div className="inp-group">
                <input
                  type="radio"
                  name={item.key}
                  className={errors[item.key] ? "radio-inp error" : "radio-inp "}
                  value="I don’t know"
                  {...register(`${item.key}`)}
                />
                <span className={errors[item.key] ? "text  textError" : "text"}>
                  I don’t know
                </span>
              </div>
            </div>
          </div>
        ))}
      </form>
    </div>
  );
};

export default StaticSymptom;
