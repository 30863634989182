import { useContext, useEffect } from "react";
import { AppContext } from "../../utils/AppContext";

const YesNoQuestion = () => {
const {prevButtonRef,setCheckupPage}=useContext(AppContext)
  
  /* ---------------------------- Prev Button Usage --------------------------- */
  useEffect(() => {
    const prevButton = prevButtonRef && prevButtonRef.current;

    const prevButtonClickHandler = () => {
      setCheckupPage("dynamic-symptom");
    };

    if (prevButton) {
      prevButton.addEventListener("click", prevButtonClickHandler);

      return () => {
        // Cleanup event listener on component unmount
        prevButton.removeEventListener("click", prevButtonClickHandler);
      };
    }
  }, [prevButtonRef, setCheckupPage]);

  return (
    <div className="yes-no-question">
      <div className="row">
        <div className="card active" onClick={()=>{setCheckupPage("pain-level")}}>
          <div className="icon">
            <svg
              width="100"
              height="100"
              viewBox="0 0 100 100"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.585 50.0003L38.1683 73.5837L85.4183 26.417"
                stroke="#095D7E"
                strokeWidth="12.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <h4 className="caption">Yes</h4>
        </div>
        <div className="card" onClick={()=>{setCheckupPage("pain-level")}}>
          <div className="icon">
            <svg
              width="101"
              height="100"
              viewBox="0 0 101 100"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M26.748 73.5837L73.9147 26.417"
                stroke="#095D7E"
                strokeWidth="12.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M73.9147 73.5837L26.748 26.417"
                stroke="#095D7E"
                strokeWidth="12.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <h4 className="caption">No</h4>
        </div>
        <div className="card" onClick={()=>{setCheckupPage("pain-level")}}>
          <div className="icon">
            <svg
              width="101"
              height="100"
              viewBox="0 0 101 100"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M58.998 20.8343L88.1641 50.0004L58.998 79.1665"
                stroke="#095D7E"
                strokeWidth="12.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M17.332 49.9999L83.9987 49.9999"
                stroke="#095D7E"
                strokeWidth="12.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <h4 className="caption">Don’t know</h4>
        </div>
      </div>
    </div>
  );
};

export default YesNoQuestion;
