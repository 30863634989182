import React from "react";
import ResultSymptom from "../../component/result/ResultSymptom";
import ResultGeneralInfo from "../../component/result/ResultGeneralInfo";
import AnswerInfo from "../../component/result/AnswerInfo";
import FeedBack from "../../component/result/FeedBack";

const ResultPage = () => {
  return (
    <>
      <ResultGeneralInfo />
      <ResultSymptom />
      <AnswerInfo />
      <FeedBack />
    </>
  );
};

export default ResultPage;
