const checkUpMap = [
  {
    id: 1,
    step: 1,
    stepName:"Introduction",
    sturucture:"general-info",
  },
  {
    id: 2,
    step: 2,
     stepName:"Patient",
    sturucture:"personal-select",
  },
  {
    id: 3,
    step: 2,
     stepName:"Patient",
    sturucture:"gender-select",
  },
  {
    id: 4,
    step: 2,
     stepName:"Patient",
    sturucture:"specific-info",
  },
  {
    id: 5,
    step: 2,
     stepName:"Patient",
    sturucture:"static-symptom",
  },
  {
    id: 6,
    step: 3,
     stepName:"Symptoms",
    sturucture:"select-symptom",
  },
  {
    id: 7,
    step: 4,
     stepName:"Regions",
    sturucture:"select-region",
  },
  {
    id: 8,
    step: 5,
     stepName:"Interview",
    sturucture:"dynamic-symptom",
  },
  {
    id: 9,
    step: 5,
     stepName:"Interview",
    sturucture:"yes-no-question",
  },
  {
    id: 10,
    step: 5,
     stepName:"Interview",
    sturucture:"pain-level",
  },
  {
    id: 11,
    step: 6,
    stepName:"Results",
    sturucture:"result",
  },
];
export default checkUpMap;
