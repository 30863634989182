/* ---------------------------------- React --------------------------------- */
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../utils/AppContext";
import checkUpMap from "../../db/checkUpMap";

const ProgressBar = () => {
  const [data, setData] = useState(null);
  const { checkupPage } = useContext(AppContext);
  useEffect(() => {
    if (checkupPage) {
      let handleStep = checkUpMap.find(
        (item) => item.sturucture === checkupPage
      );
      setData(handleStep);
    }
  }, [checkupPage]);
  return (
    <>
      <div className="progress-bar ">
        <div className={`radialProgressBar progress-${data?.step} `}>
          <div className="info-area">
            <span className="text">{data?.stepName}</span>

            <span className="circle">{data?.step}/6</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProgressBar;
