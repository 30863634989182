import React from "react";

const HomeCaption = ({ title, detail, longText }) => {
  return (
    <div className="home-caption">
      <span className="line"></span>
      <h2 className="title">{title}</h2>
      {detail && <p className="detail" >{detail}</p>}
      {longText && (
        <span className="button">Learn More</span>
      )}
    </div>
  );
};

export default HomeCaption;
