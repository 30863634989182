/* ---------------------------------- React --------------------------------- */
import React, { useContext, useEffect, useState } from "react";

/* -------------------------------- Conponent ------------------------------- */
import BodyInfo from "./BodyInfo";

/* ----------------------------- Local Database ----------------------------- */
import questionsDb from "../../db/questionsDb";

/* --------------------------------- Context -------------------------------- */
import { AppContext } from "../../utils/AppContext";

const SpecificInfo = () => {
  /* --------------------------------- Context -------------------------------- */
  const {
    checkupPage,
    setCheckupPage,
    prevButtonRef,
    nextButtonRef,
    bodyInfo,
  } = useContext(AppContext);

  /* ------------------------------- Local State ------------------------------ */
  const [data, setData] = useState([]);
  const [staticData, setStaticData] = useState([
    {
      id: 1,
      type: "age",
      defaultValue: "",
    },
    {
      id: 2,
      type: "weight",
      defaultValue: "",
    },
    {
      id: 3,
      type: "height",
      defaultValue: "",
    },
  ]);
  const [contextData, setContextData] = useState(false);




/* ----------------------------- Set Deaultvalue ---------------------------- */
  useEffect(() => {
    if (!contextData) {
      let updateData = staticData.map((item) => {
        return { ...item, defaultValue: bodyInfo[item.type] };
      });
      setStaticData(updateData);
      setContextData(true);
    }
    //
  }, [bodyInfo, contextData, staticData]);

  /* ------------------------------ Pick Data Db ------------------------------ */
  useEffect(() => {
    if (contextData) {
      questionsDb.map((item) => {
        if (item.page === "specific-info") {
          const updatedQuestions = item.questions.map((question) => {
            const newData = staticData.find(
              (data) => data.type === question.type
            );
            return newData
              ? { ...question, defaultValue: newData?.defaultValue }
              : question;
          });
          setData(updatedQuestions);
        }

        return item;
      });
    }
  }, [checkupPage, contextData, staticData]);


  /* ---------------------------- Prev Button Usage --------------------------- */
  useEffect(() => {
    const prevButton = prevButtonRef && prevButtonRef.current;

    const handleClick = () => {
      setCheckupPage("gender-select");
    };

    if (prevButton) {
      prevButton.addEventListener("click", handleClick);

      return () => {
        // Cleanup event listener on component unmount
        prevButton.removeEventListener("click", handleClick);
      };
    }
  }, [prevButtonRef, setCheckupPage]);

  /* ---------------------------- Next Button Usage --------------------------- */
  useEffect(() => {
    const nextButton = nextButtonRef && nextButtonRef.current;

    const handleClick = () => {
      setCheckupPage("static-symptom");
    };

    if (nextButton) {
      nextButton.addEventListener("click", handleClick);

      return () => {
        // Cleanup event listener on component unmount
        nextButton.removeEventListener("click", handleClick);
      };
    }
  }, [nextButtonRef, setCheckupPage]);

  return (
    <div className="specific-info">
      {data &&
        data.map((item) => (
          <BodyInfo
            questionText={item.question}
            type={item.type}
            max={item.max}
            defaultValue={item?.defaultValue}
            key={item.type}
          />
        ))}
    </div>
  );
};

export default SpecificInfo;
