
const staticSymptom=[
    {id: 1 ,
    key:"line1",
    symptom:"I have diabetes",
    },
    {id:2,
    key:"line2",
    symptom:"I’m overweight or obese",
    },
    {id:3,
    key:"line3",
    symptom:"I have hypertension",
    },
    {id:4,
    key:"line4",
    symptom:"I have smoked cigarettes for at least 10 years",
    },
    {id:5,
    key:"line5",
    symptom:"I’ve recently suffered an injury",
    },
    {id:6,
    key:"line6",
    symptom:"I have high cholesterol",
    },
]
export default staticSymptom;