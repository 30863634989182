import infoIconFirst from "../assets/img/info-icon-first.svg";
import infoIconSecond from "../assets/img/info-icon-second.svg";
import infoIconThird from "../assets/img/info-icon-third.svg";
const homeTest1 = [
  {
    id: 1,
    img: infoIconFirst,
    title: "Cancer Care",
    text: "The gradual accumulation of information about",
  },
  {
    id: 2,
    img: infoIconSecond,
    title: "Online Appoinment",
    text: "The gradual accumulation of information about",
  },
  {
    id: 3,
    img: infoIconThird,
    title: "Book now",
    text: "The gradual accumulation of information about",
  },
];
export default homeTest1;
