import { createContext, useState } from "react";

export const AppContext = createContext();
export const MainContext = ({ children }) => {
  /* ---------------------------- Complete Checkup ---------------------------- */
  const [complete, setComplete] = useState(false);

  /* ---------------------------- Component Render ---------------------------- */
  const [checkupPage, setCheckupPage] = useState("general-info");

  /* ------------------------------ Specific Info ----------------------------- */
  const [bodyInfo, setBodyInfo] = useState({
    age: "18",
    weight: "50",
    height: "150",
  });
  const [gender, setGender] = useState("");
  const [personal, setPersonal] = useState("");

  /* -------------------------- SideBar Open && close ------------------------- */
  const [symptomBar, setSymptomBar] = useState();
  const [sideBarCaption,setSideBarCaption]=useState(null)

/* ------------------------ Static Symptom Form Data ------------------------ */
const [staticSymptomData, setStaticSymptomData] = useState(null);

  /* ---------------------------- Symptom Keywords ---------------------------- */
  const [keywords, setKeywords] = useState(["Head", "Eyes", "Upper abdomen"
]);

  /* --------------------------------- Region --------------------------------- */
  const [continent, setContinent] = useState([]);

  /* -------------------------------- PainLevel ------------------------------- */
  const [painLevel, setPainlevel] = useState(1);

  /* ------------------------------ Video Visible ----------------------------- */
  const [videoVisible, setVideoVisible] = useState(false);

  /* -------------------------- Next Prev Button Ref -------------------------- */
  const [nextButtonRef, setNextButtonRef] = useState([]);
  const [prevButtonRef, setPrevButtonRef] = useState([]);

  /* ---------------------------------- Step ---------------------------------- */
  const [step, setStep] = useState(1);


  const removeKeywords = (symptom) => {
    if (keywords.includes(symptom)) {
      setKeywords(keywords.filter((key) => key !== symptom));
    }
  };

  const globalStates = {
    // Export State

    complete,
    setComplete,
    checkupPage,
    setCheckupPage,
    bodyInfo,
    setBodyInfo,
    gender,
    setGender,
    personal,
    setPersonal,
    staticSymptomData,
    setStaticSymptomData,
    symptomBar,
    setSymptomBar,
    sideBarCaption,
    setSideBarCaption,
    keywords,
    setKeywords,
    continent,
    setContinent,
    painLevel,
    setPainlevel,
    nextButtonRef,
    prevButtonRef,
    setNextButtonRef,
    setPrevButtonRef,
    step,
    setStep,
    videoVisible,
    setVideoVisible,
    // function
    removeKeywords,
  };
  return (
    <AppContext.Provider value={globalStates}> {children}</AppContext.Provider>
  );
};
