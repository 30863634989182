const questionsDb = [
  {
    id: 1,
    page: "personal-select",
    question: "Who is checkup for?",
    firstTitle: "Myself",
    secondTitle: "Someone else",
  },
  {
    id: 2,
    page: "gender-select",
    question: "What is your sex?",
    firstTitle: "Female",
    secondTitle: "Male",
  },

  {
    id: 3,
    page: "specific-info",

    questions: [
      {
        id: 1,
        type: "age",
        max: "100",
        question: "How old are you?",
      },
      {
        id: 2,
        type: "weight",
        max: "150",
        question: "What is weight?",
      },
      {
        id: 3,
        type: "height",
        max: "220",
        question: "What is the height?",
      },
    ],
  },
  {
    id: 4,
    page: "select-symptom",
    question: "Add your symptoms",
  },
  {
    id: 5,
    page: "static-symptom",
    question: "Please check all the statements below that apply to you",
  },
  {
    id: 6,
    page: "select-region",
    question: "Select regions",
  },
  {
    id: 7,
    page: "dynamic-symptom",
    question: "Do you have any of the following symtoms?",
  },
  {
    id: 8,
    page: "yes-no-question",
    question: "Do you have pain any part of your face?",
  },
  {
    id: 9,
    page: "pain-level",
    question: "How strong is the headache?",
  },
];
export default questionsDb;
