import React from "react";
import Button from "../../component/Button";

const Hero = () => {
  return (
    <section className="hero">
      <div className="container">
        <div className="row">
          <div className="info">
            <h1 className="title">A Great Place to Receive Care</h1>
            <p className="detail">
              We always focus on providing initial help to our loved ones and
              ourselves
            </p>
            <Button text={"Start checkup"} link={"/check-up"} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
