import React, { useContext } from "react";
import { AppContext } from "../../utils/AppContext";
import BodyManMobile from "../checkup-body/BodyManMobile";
import BodyWomanMobile from "../checkup-body/BodyWomanMobile";

const BodyMobile = () => {
  const { gender } = useContext(AppContext);
  return <>{gender === "woman" ? <BodyWomanMobile /> : <BodyManMobile />}</>; 
};

export default BodyMobile;
